"use client";

import React from "react";
import {
  Button,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
} from "@gonoodle/gn-universe-ui";
import { usePathname } from "next/navigation";
import { concatenateQueryParams } from "@gonoodle/gn-universe-utils";

import { ROUTE_PREFIX } from "../../constants";
import { useIntendedPath, useRouter } from "../../hooks";

export default function RedirectToRegistrationButton({
  children = "Create a Free Account",
  queryParams = {},
  referrer = {},
  onPress = () => {},
  ...delegated
}) {
  const router = useRouter();
  const pathname = usePathname();
  const { intendedPath } = useIntendedPath();

  const currentIntendedPath = intendedPath || pathname;

  return (
    <Button
      variant={BUTTON_VARIANTS.light}
      size={BUTTON_SIZES.sm}
      onPress={(e) => {
        router.push(
          concatenateQueryParams(ROUTE_PREFIX.REGISTRATION, {
            ...queryParams,
            intended_path: currentIntendedPath,
          }),
          undefined,
          referrer,
        );

        onPress(e);
      }}
      {...delegated}
    >
      {children}
    </Button>
  );
}
